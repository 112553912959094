@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer components {

  .loginscreen {
    @apply h-screen bg-custom-bg
  }

  .login-form-holder {
    @apply max-w-md w-full p-4
  }

  .login-logo {
    @apply w-40
  }

  .app-para {
    @apply text-red-400 underline underline-offset-2
  }

  .form-gp {
    @apply w-full
  }

  .main-layout {
    @apply bg-custom-bg h-auto min-h-screen
  }

  .submenu-list {
    @apply flex items-center sm:justify-start justify-between
  }

  .submenu-list li {
    @apply sm:mr-5 sm:text-sm font-medium cursor-pointer m-0 text-xs sm:text-left text-center
  }

  .profile-meta {
    @apply mt-5 pb-3 border-b-tiny border-black
  }


  .form-stat{
    @apply relative font-bold
  }

  .form-lineup{
    @apply sm:grid md:grid-cols-4 grid-cols-3 gap-2 border-b-smaller relative align-middle px-2 sm:h-14 items-center h-auto
  }


  .form-lineup li{
    @apply text-sm
  }

  .form-line-holder{
    @apply pl-14 relative border-b-slate-300 box-border sm:mb-0 mb-4 sm:inline flex justify-center
  }

  .form-line-holder:last-child .form-lineup{
    @apply border-b-0
  }
  
  .form-line-holder span {
    @apply absolute sm:left-6 top-1/2 sm:flex items-center justify-center w-8 h-8 bg-white border-white rounded-full z-10 box-border pl-1 hidden
  }

  .inner-child{
    @apply relative after:border-r-smaller after:absolute after:h-4/5 after:top-6 sm:after:w-10 after:border-slate-300 after:border-dashed after:w-4 sm:after:inline after:hidden
  }

  .status_h3{
    @apply relative pl-4
  }

  .btn_theme{
    @apply bg-pm-col text-white inline-block w-40 text-center capitalize h-10 leading-10 rounded-md text-xs hover:bg-pm-col-dark transition-all
  }

  .form-action-trigger{
    @apply absolute bottom-0 md:py-3 left-0 bg-slate-400 w-full p-3
  }
  .form-submit-btn{
    @apply inline-block text-white h-10 leading-10 rounded-3xl transition-all uppercase text-sm
  }
  .in-compl{
    @apply inline-block w-4 h-4 border-dashed  border-tiny rounded-full
  }
  .in-compl.active{
    @apply border-orange-500
  }
  .form-submit-btn:disabled{
    @apply bg-slate-500 hover:bg-slate-500 cursor-not-allowed
  }
  .back_dash{
    @apply absolute top-4 right-4 text-xs
  }
  .user-form{
    @apply min-h-screen relative pb-28 md:ml-80 md:pr-6 flex flex-col justify-center pr-0 ml-0 bg-slate-200
  }
  .inner-form{
    @apply px-5
  }
  .sec-head{
    @apply font-semibold text-black text-lg mb-2
  }
  .sec-subtl{
    @apply font-normal text-sm text-slate-500
  }
  .seperator{
    @apply border-tiny border-t-slate-300 inline-block my-4 mx-6
  }
  .aside-menu{
    @apply h-full bg-pm-col md:pl-5 flex items-start flex-col justify-center md:fixed w-full md:max-w-xs top-0 left-0 z-10 relative p-2 box-border
  }
  .disabled-item{
    @apply text-gray-400 cursor-not-allowed !important 
  }
}

.margin-0{
  margin: 0 !important;
}

.grid-form-gp{
  display: grid !important;
}

.form-line-holder span{
  transform: translateY(-50%)
}

.status_h3::before{
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #f00;
  display: inline-block;
}

.status_h3.completed::before{
  background-color: #03A832
}
.status_h3.completed{color: #03A832;}
.status_h3.ongoing::before{
  background-color: #FB6B2E
}
.status_h3.ongoing{color: #FB6B2E;}
.status_h3.notstarted::before{
  background-color: #54504E
}
.status_h3.notstarted{color: #54504E;}

.form-action-trigger::before,
.form-action-trigger::after{
  content: '';
  position: absolute;
  left: 0;
  width: 50px;
  height: 100%;
  background-color: #94a3b8;
  top: 0;
  display: none;
}
/* .form-action-trigger::after{
  left: auto;
  right: -32px;
} */
.form-submit-btn{
  max-width: 200px;
  width: 100%;
  text-align: center;
  background-color: #1B1B43;
}
.form-submit-btn:hover{
  background-color: #06061D
}


.MuiStepLabel-label{
  color: #e2e2e2;
}
.MuiStepLabel-label.Mui-completed{
  color: #03A832 !important;
}
.MuiStepLabel-label.Mui-active{
  color: #FB6B2E !important;
}

.custom-swicth .MuiFormControlLabel-root{
  justify-content: space-between;
  margin-left: 0 !important;
}

.MuiTableCell-root{color: #fff !important;border-bottom: 1px solid rgb(60 59 59) !important;}

@media only screen and (max-width: 600px){
  .custom-swicth{flex-direction: column;}
  .col-span-2-mob .col-span-2{ grid-column: span 1 / span 1}
  .status_h3{
    margin: 5px 0;
  }
  .form-line-holder{padding-bottom: 0.5rem;border-bottom: 1px dashed rgb(167, 167, 167);margin-bottom: 0.5rem;padding-left: 0;}
  .form-line-holder:last-child{border: none;}
  .form-lineup{display: flex;flex-wrap: wrap;justify-content: space-between;width: 100%;}
  .form-lineup li:last-child,
  .form-lineup li:last-child a{width: 100%;}
  .responsive-table tbody > tr {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .responsive-table thead,
  .responsive-table tbody > tr > td:first-child{
    display: none;
    
  }
  .responsive-table tbody > tr > td::before{
    content: attr(data-head);
    top: 0;
    display: block;
    color: #66686c;
  }
}

.form-line-holder span{display: none;}
.inner-child::after{display: none;}
.MuiMenuItem-root.Mui-selected {
  background-color: rgb(255 211 98) !important;
}


.dt-inner-form .MuiFormControl-root,
.dt-inner-form .col-span-2,
.work-history-form .MuiFormControl-root,
.work-history-form .col-span-2{
  width: 100%;
}
.work-history-form{
  display: flex;
  flex-wrap: wrap;
}


.loginscreen{
  background-image: url('login-frame.png');
  background-size: 45%;
    background-repeat: no-repeat;
    background-position: bottom right;
}

@media only screen and (max-width: 600px){
  .loginscreen{
    background-size: 100%;
  }
}